import styled from "styled-components";
import { ReactComponent as Search } from "./search.svg";
import brand from "../../styles/theme/brand";

const SearchIcon = styled(Search)`
  path {
    stroke: ${(p) => p.bgStyle === "gradient" ? `rgb(${brand.common.black})` : p.home ? `rgb(${brand.common.white})` : p.theme.fontPrimary};
  }
`;

export default SearchIcon;
