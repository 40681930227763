import CheckIcon from "../icons/checkIcon";
import TimerIcon from "../icons/timerIcon";
import React from "react";
import { useSelector } from "react-redux";
import { finalizedHeightSelector } from "../../store/reducers/chainSlice";

export default function FinalizedState({ bgStyle, height }) {
  const finalizedHeight = useSelector(finalizedHeightSelector);
  const isFinalized = height <= finalizedHeight;
  return isFinalized ? <CheckIcon bgStyle={bgStyle} /> : <TimerIcon bgStyle={bgStyle} />;
}
