import styled from "styled-components";
import { ReactComponent as CaretDown } from "./caret-down.svg";
import brand from "../../styles/theme/brand";

const CaretDownIcon = styled(CaretDown)`
  path {
    stroke: ${(p) => p.menu ? `rgb(${brand.common.white})`: p.theme.fontPrimary};
  }
`;

export default CaretDownIcon;
