import styled from "styled-components";
import { Inter_14_500 } from "../../styles/text";

export const TextSecondary = styled.span`
  ${Inter_14_500};
  color: ${(p) => p.bgStyle === "gradient" ? p.theme.black : p.theme.searchInputBorder};
`;

export const TextTertiary = styled.span`
  ${Inter_14_500};
  color: ${(p) => p.bgStyle === "gradient" ? p.theme.searchInputBorder : p.theme.fontTertiary};
`;
