import styled from "styled-components";
import { ReactComponent as Moon } from "./moon.svg";

const MoonIcon = styled(Moon)`
  path {
    stroke: ${(p) => p.theme.white};
  }

  #paint0_linear_5874_7536 {
    stop:first-child {
      stop-color: ${({ theme }) => theme.fontPrimary};
    }

    stop:last-child {
      stop-color: ${({ theme }) => theme.fontPrimary};
    }
  }
`;

export default MoonIcon;