import styled from "styled-components";
import { ReactComponent as Empty } from "./empty.svg";

const EmptyIcon = styled(Empty)`
  color: ${(p) => p.theme.fontQuaternary};
  width: 64px;
  height: 64px;
`;

export default EmptyIcon;
