import styled from "styled-components";
import { useIsDark } from "../utils/hooks";

const Wrapper = styled.div`
  position: relative;
  z-index: -10;
`;

const BgWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: ${(p) => p.dark ? "734px" : "294px"};
  background-color: ${(p) => p.theme.fillPanel};
`;

const Bg = styled.div`
  width: 100%;
  height: 100%;
  background: url("/imgs/zk-verify-bg.svg") center/cover;
`;

const Base = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${(p) => p.theme.fillBase};
  z-index: -1;
`;

export default function Background() {
  const isDark = useIsDark();

  return (
    <Wrapper>
      <BgWrapper dark={isDark}>
        <Bg />
      </BgWrapper>

      <Base />
    </Wrapper>
  );
}
