// import { getChainSettings } from "../../utils/chain";
import brand from "./brand";
import light from "./light";

// const chainSetting = getChainSettings();

const dark = {
  ...light,
  fontPrimary: `rgb(${brand.common.white})`,
  fontPrimaryInverse: `rgb(${brand.common.black})`,
  fontSecondary: `rgba(${brand.common.white}, 0.55)`,
  fontTertiary: `rgb(${brand.dark.grey})`,
  fontQuaternary: `rgba(${brand.common.white}, 0.1)`,
  fontPositive: `rgb(${brand.common.green})`,
  fontPending: "yellow",
  fontNegative: "red",
  strokeBase: `rgb(${brand.dark.stroke})`,
  strokeBox: `rgb(${brand.common.darkGrey})`,
  strokeBoxSelected: `rgb(${brand.common.midGrey})`,
  fillPanel: `rgb(${brand.dark.panel})`,
  fillPopup: `rgb(${brand.dark.panel})`,
  fillPopupHover: `rgb(${brand.dark.base})`,
  fillSub: `rgb(${brand.dark.base})`,
  fillBase: `rgb(${brand.dark.base})`,
  fillPanelBlanket: `rgba(${brand.dark.panel}, 0.8)`,
  fillAlpha: `rgba(${brand.common.white}, 0.05)`,
  fillBeta: `rgba(${brand.common.white}, 0.02)`,
  fillGamma: `rgba(${brand.common.white}, 0)`,
};

export default dark;
