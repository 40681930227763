import styled from "styled-components";
import { ReactComponent as Sun } from "./sun.svg";

const SunIcon = styled(Sun)`
  path {
    stroke: ${(p) => p.theme.white};
  }

  #paint0_linear_5874_7536 {
    stop:first-child {
      stop-color: ${({ theme }) => theme.fontPrimary};
    }

    stop:last-child {
      stop-color: ${({ theme }) => theme.fontPrimary};
    }
  }
`;

export default SunIcon;