import styled from "styled-components";
import { ReactComponent as Timer } from "./timer.svg";

const TimerIcon = styled(Timer)`
  path {
    stroke: ${(p) => p.bgStyle === "gradient" ? p.theme.black : p.theme.fontTertiary};
  }

  #paint0_linear_5874_7536 {
    stop:first-child {
      stop-color: ${({ theme }) => theme.fontPrimary};
    }

    stop:last-child {
      stop-color: ${({ theme }) => theme.fontPrimary};
    }
  }
`;

export default TimerIcon;